import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MissionStatus, MissionTableState } from 'types/missionTable'
import {
  getMissionAgents, getMissionTeams, getMissionsTC, getTCFilters, patchTCFilters, updateMission,
} from './thunks'

const emptyFilters = {
  agents: [],
  agents_selected: [],
  mission_modeles: [],
  mission_modeles_selected: [],
  statuts: [],
  statuts_selected: [],
  gmao_modeles: 'Autres',
  gmao_modele_else_selected: false,
}

const initialState: MissionTableState = {
  teams: [],
  teamsLoading: false,
  agents: [],
  agentsLoading: false,
  activeTeams: [],
  activeAgentsCount: 0,
  missionsTC: [],
  missionsPreAssigned: [],
  missionsTCLoading: false,
  filters: emptyFilters,
}

export const missionTableSlice = createSlice({
  name: 'missionTable',
  initialState,
  reducers: {
    clearMissionTableState: () => initialState,
    setAgentStatus: (state, action: PayloadAction<{agentId: string, newStatus: boolean}>) => {
      const agent = state.agents.find(a => a.id === action.payload.agentId)
      const team = state.teams.find(t => t.id === agent?.equipe)
      if (agent) {
        const newAgents = state.agents.map(a => (a.id === action.payload.agentId
          ? { ...a, active: action.payload.newStatus } : a))
        state.agents = newAgents
        state.activeAgentsCount = newAgents.filter(a => a.selected).length
      }
      if (team) {
        const newTeams = state.teams.map(t => (t.id === team.id
          ? { ...t, active: state.agents.filter(a => a.equipe === team.id && a.selected).length > 0 } : t))
        state.teams = newTeams
        state.activeTeams = newTeams.filter(t => t.active).map(t => t.libelle)
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getMissionTeams.pending, state => {
      state.teamsLoading = true
    })
    builder.addCase(getMissionTeams.fulfilled, (state, action) => {
      state.teamsLoading = false
      state.teams = action.payload
      state.activeTeams = action.payload.filter(team => team.active).map(team => team.libelle)
    })
    builder.addCase(getMissionTeams.rejected, state => {
      state.teamsLoading = false
    })
    builder.addCase(getMissionAgents.pending, state => {
      state.agentsLoading = true
    })
    builder.addCase(getMissionAgents.fulfilled, (state, action) => {
      state.agentsLoading = false
      state.agents = action.payload
      state.activeAgentsCount = action.payload.filter(agent => agent.selected).length
    })
    builder.addCase(getMissionAgents.rejected, state => {
      state.agentsLoading = false
    })
    builder.addCase(getMissionsTC.pending, state => {
      state.missionsTCLoading = true
    })
    builder.addCase(getMissionsTC.fulfilled, (state, action) => {
      const [missionsTC, missionsPreassigned] = action.payload
      if (missionsTC.status === 'fulfilled') {
        state.missionsTC = missionsTC.value
      }
      if (missionsPreassigned.status === 'fulfilled') {
        state.missionsPreAssigned = missionsPreassigned.value
      }
      state.missionsTCLoading = false
    })
    builder.addCase(getMissionsTC.rejected, state => {
      state.missionsTCLoading = false
    })
    builder.addCase(getTCFilters.pending, state => {
      state.filters = emptyFilters
    })
    builder.addCase(getTCFilters.fulfilled, (state, action) => {
      state.filters = action.payload
    })
    builder.addCase(patchTCFilters.fulfilled, (state, action) => {
      state.filters = action.payload
    })
    builder.addCase(updateMission.fulfilled, (state, action) => {
      if (action.payload.statut === MissionStatus.preAssigned) {
        state.missionsPreAssigned = state.missionsPreAssigned
          .map(m => (m.id === action.payload.id ? action.payload : m))
      } else if (action.payload.archivee) {
        state.missionsTC = state.missionsTC.filter(m => m.id !== action.payload.id)
        state.missionsPreAssigned = state.missionsPreAssigned.filter(m => m.id !== action.payload.id)
      } else {
        state.missionsPreAssigned = state.missionsPreAssigned.filter(m => m.id !== action.payload.id)
        state.missionsTC = state.missionsTC.map(m => (m.id === action.payload.id ? action.payload : m))
      }
    })
  },
})

export const {
  clearMissionTableState,
  setAgentStatus,
} = missionTableSlice.actions

export default missionTableSlice.reducer
