/* eslint-disable @typescript-eslint/no-explicit-any */
import MAIN_API from 'config/config'

const formatPath = (path: string, queryParams = {}) => {
  const baseUrl = `${MAIN_API.api}${path}${path.slice(-1) !== '/' ? '/' : ''}`
  if (Object.keys(queryParams).length) {
    const params = new URLSearchParams(queryParams)
    return `${baseUrl}?${params}`
  }
  return baseUrl
}

export const get = async <T = any> (url: string, queryParams?: object, params?: RequestInit) => fetch(
  formatPath(url, queryParams),
  {
    method: 'GET',
    ...params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      ...(params && params.headers ? params.headers : {}),
    },
  },
).then(async response => {
  const content = await response.json()
  if (!response.ok) {
    const errorMessage = content?.message || response.statusText
    throw new Error(errorMessage)
  }
  return content as T
})

export const post = async <T = any> (url: string, data: object, params?: RequestInit) => fetch(formatPath(url), {
  method: 'POST',
  body: JSON.stringify(data),
  ...params,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    ...(params && params.headers ? params.headers : {}),
  },
}).then(async response => {
  const content = await response.json()
  if (!response.ok) {
    const errorMessage = content?.message || response.statusText
    throw new Error(errorMessage)
  }
  return content as T
})

export const patch = async <T = any> (url: string, data: object, params?: RequestInit) => fetch(formatPath(url), {
  method: 'PATCH',
  body: JSON.stringify(data),
  ...params,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    ...(params && params.headers ? params.headers : {}),
  },
}).then(async response => {
  const content = await response.json()
  if (!response.ok) {
    const errorMessage = content?.message || response.statusText
    throw new Error(errorMessage)
  }
  return content as T
})

export const put = async <T = any> (url: string, data: object, params?: RequestInit) => fetch(formatPath(url), {
  method: 'PUT',
  body: JSON.stringify(data),
  ...params,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    ...(params && params.headers ? params.headers : {}),
  },
}).then(async response => {
  const content = await response.json()
  if (!response.ok) {
    const errorMessage = content?.message || response.statusText
    throw new Error(errorMessage)
  }
  return content as T
})

export const deleteRequest = async (url: string, params?: RequestInit) => fetch(formatPath(url), {
  method: 'DELETE',
  ...params,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    ...(params && params.headers ? params.headers : {}),
  },
}).then(async response => {
  const content = await response.json()
  if (!response.ok) {
    const errorMessage = content?.message || response.statusText
    throw new Error(errorMessage)
  }
  if (response.status === 204) {
    return {}
  }
  return content
})
