import { Close } from '@mui/icons-material'
import { MissionStatus, MissionTC } from 'types'
import { Button, ButtonStyle } from 'components'
import { handlePatchMissionTC } from 'services'
import moment from 'moment'
import { cleanFormValues, cleanInProgressFormValues, useAppDispatch } from 'utils'
import { getMissionsTC } from 'reducers/missionTable/thunks'
import { useHandlers, useRequiredFields } from './formHandlers'
import MissionTCFields from './fields/MissionTCFields'

interface Props {
  handleClose: () => void;
  technicalCenterId: string;
  mission: MissionTC;
  ordering: string;
}

export default function ModalPatchMissionTC({
  handleClose, technicalCenterId, mission, ordering,
}: Props) {
  const dispatch = useAppDispatch()
  const {
    formValues,
    handleInputChange,
    handleCheckboxChange,
    handleTimeChange,
    handleMultiSelectChange,
    handleChangeRame,
    handleUmDetailsChange,
    sortedMissionsOptions,
    sortedAdditionalMissionsOptions,
    handleSelectModele,
    pendingGmao,
  } = useHandlers(technicalCenterId, {
    rame: mission.rame,
    um: mission.um,
    um_details: mission.um_details || '',
    modele: mission.modele?.id,
    gmao: mission.gmao?.id,
    prioritaire: mission.prioritaire,
    chantier_depart: mission.chantier_depart?.id || '',
    chantier_arrivee: mission.chantier_arrivee?.id || '',
    voie_depart: mission.voie_depart?.id || '',
    voie_arrivee: mission.voie_arrivee?.id || '',
    heure_debut_theorique: moment(mission.heure_debut_theorique),
    heure_fin_theorique: moment(mission.heure_fin_theorique),
    agent: mission.agent?.id || '',
    sens_depart: mission.sens_depart?.id || '',
    via: mission.via || '',
    missions_supplementaires: mission.missions_supplementaires.map(m => m?.modele?.id || m?.gmao?.id),
    observation_com: mission.observation_com || '',
    pancartage: mission.pancartage || '',
    statut: mission.statut,
  }, mission?.gmao, mission?.missions_supplementaires)
  const [requiredFields] = useRequiredFields(formValues, sortedMissionsOptions)

  const handlePatchAssigned = handlePatchMissionTC(
    technicalCenterId,
    mission.id,
    () => {
      handleClose()
      dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
    },
    mission.statut === MissionStatus.inProgress ? cleanInProgressFormValues(formValues)
      : cleanFormValues(formValues),
  )

  const handlePatchPreAssigned = handlePatchMissionTC(
    technicalCenterId,
    mission.id,
    () => {
      handleClose()
      dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
    },
    {
      ...cleanFormValues(formValues),
      statut: MissionStatus.assigned,
    },
    true,
  )

  return (
    <div className="modal-patch-missiontc">
      <div className="header">
        <h2>{`Modifier la mission "${mission.modele?.libelle || mission.gmao?.libelle}"`}</h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <MissionTCFields
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSelectMission={handleSelectModele}
        handleCheckboxChange={handleCheckboxChange}
        handleTimeChange={handleTimeChange}
        handleMultiSelectChange={handleMultiSelectChange}
        handleChangeRame={handleChangeRame}
        handleUmDetailsChange={handleUmDetailsChange}
        technicalCenterId={technicalCenterId}
        requiredFields={requiredFields}
        isInProgress={mission.statut === MissionStatus.inProgress}
        missionOptions={sortedMissionsOptions}
        additionalMissionOptions={sortedAdditionalMissionsOptions}
        patchDisable
        waitGMAO
        pendingGMAO={pendingGmao}
      />

      <div className="actions">
        <Button
          text="Annuler"
          style={ButtonStyle.light}
          onClick={handleClose}
        />
        <Button
          text="Sauvegarder les modifications"
          onClick={handlePatchAssigned}
          disabled={pendingGmao}
        />
        {formValues.statut === MissionStatus.preAssigned && (
          <Button
            text="Sauvegarder les modifications et affecter"
            onClick={handlePatchPreAssigned}
            disabled={!formValues.agent || pendingGmao}
          />
        )}
      </div>

    </div>
  )
}
