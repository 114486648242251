import { deleteRequest, patch, post, parseErrorMessage } from 'utils'
import terms from 'assets/terms'
import moment from 'moment'
import { setSnackbar } from 'reducers/app'
import { setAgentStatus } from 'reducers/missionTable'
import { store } from 'reducers/store'
import { MissionAgent, SnackbarSeverity, MissionTC, MissionBatchAction, MissionStatus } from 'types'
import { getMissionAgents, getMissionTeams } from 'reducers/missionTable/thunks'

export const handleUpdateAgents = (
  centerId: string,
  agents: MissionAgent[],
  activeAgents: string[],
  callback: () => void,
) => () => {
  Promise.all(agents.map(agent => {
    const newStatus = activeAgents.includes(agent.id)
    if (agent.selected !== newStatus) {
      return patch(`/r2d2/technicentres/${centerId}/agent/${agent.id}/`, { selected: newStatus })
        .then(() => store.dispatch(setAgentStatus({ agentId: agent.id, newStatus })))
    }
    return Promise.resolve()
  })).then(() => {
    callback()
    store.dispatch(setSnackbar({ message: terms.Messages.success.missionTable.updateAgents,
      severity: SnackbarSeverity.SUCCESS }))
    store.dispatch(getMissionTeams(centerId))
    store.dispatch(getMissionAgents(centerId))
  }).catch(e => {
    const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.updateAgents)
    store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
  })
}

export const handlePostNewMissionTC = (
  centerId: string,
  callback: () => void,
) => (missionTC: Partial<MissionTC>) => () => post(
  `/r2d2/technicentres/${centerId}/missions/`,
  missionTC,
).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.postTCMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleDeleteMissionTC = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => deleteRequest(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/`,
).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.deleteTCMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePatchMissionTC = (
  centerId: string,
  missionId: string,
  callback: () => void,
  missionTC: Partial<MissionTC & {validation: string}>,
  patchGmao?: boolean,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/`,
  {
    ...missionTC,
    ...(patchGmao ? { checkGmao: true } : {}),
  },
).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.patchTCMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleValidateMissionTC = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/validation/`,
  {
    validation: moment().format(),
  },
).then(() => {
  callback()
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.rd.validateMission)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleStartMissionTC = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => handlePatchMissionTC(
  centerId,
  missionId,
  callback,
  { prise_en_compte: moment().format(), statut: MissionStatus.inProgress },
)

export const handleMissionTCPE = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => handlePatchMissionTC(centerId, missionId, callback, { presence_sur_engin: moment().format() })

export const handleMissionTCNPD = (
  centerId: string,
  missionId: string,
  callback: () => void,
) => handlePatchMissionTC(centerId, missionId, callback, { debut_npd: moment().format() })

export const handleMissionBatchAction = (
  centerId: string,
  missionIds: string[],
  callback: (response) => void,
  action: MissionBatchAction,
) => () => patch(
  `/r2d2/technicentres/${centerId}/missions/simultaneous-partial-update/`,
  {
    action,
    missions: missionIds,
  },
).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.missionBatchAction)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handlePatchAdditionnalMissionsTC = (
  centerId: string,
  missionId: string,
  newAdditionnalMissions: {id: string, done: boolean}[],
  callback: () => void,
) => () => Promise.all(newAdditionnalMissions.map(({ id, done }) => patch(
  `/r2d2/technicentres/${centerId}/missions/${missionId}/missions-supplementaires/${id}/`,
  { done },
))).then(callback).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.missionTable.patchAdditionnalMissionsTC)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})
