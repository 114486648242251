import moment from 'moment'
import './DayGapBadge.scss'

interface Props {
  time?: string;
}

export default function DayGapBadge({ time }: Props) {
  const dayDiff = moment.duration(moment(time).startOf('day').diff(moment().startOf('day'))).asDays()
  if (!time || !dayDiff) return null
  return (
    <span className="day-gap-badge">
      J
      {dayDiff > 0 ? '+' : ''}
      {dayDiff}
    </span>
  )
}
