import { deleteRequest, patch, post, parseErrorMessage } from 'utils'
import terms from 'assets/terms'
import { setSnackbar } from 'reducers/app'
import { addCerbereUser } from 'reducers/cerbere'
import {
  addTeamMember, deleteTeam, postNewTeam, removeTeamMember,
} from 'reducers/manage'
import { store } from 'reducers/store'
import { CerbereUser, ManageTeam, MissionAgent, SnackbarSeverity } from 'types'

export const handlePostNewTeam = (centerId: string, callback: () => void) => (teamName: string) => () => post(
  `/r2d2/technicentres/${centerId}/equipe/`,
  { libelle: teamName },
).then((newTeam: ManageTeam) => {
  store.dispatch(postNewTeam(newTeam))
  callback()
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.postTeam, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = e?.response?.data?.libelle || terms.Messages.errors.manage.postTeam
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleDeleteTeam = (centerId: string, teamId: string, callback: () => void) => () => deleteRequest(
  `/r2d2/technicentres/${centerId}/equipe/${teamId}/`,
).then(() => {
  store.dispatch(deleteTeam(teamId))
  callback()
  store.dispatch(setSnackbar({ message: terms.Messages.success.manage.deleteTeam, severity: SnackbarSeverity.SUCCESS }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteTeam)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleAddMemberToTeam = (
  centerId: string,
  teamId: string,
  callback: () => void,
) => (user: CerbereUser, agent: MissionAgent) => () => patch(
  `/r2d2/technicentres/${centerId}/agent/${agent.id}/`,
  { equipe: teamId },
).then(() => {
  store.dispatch(addTeamMember({ teamId, agent: { id: agent.id, cp: user.cp } }))
  store.dispatch(addCerbereUser(user))
  callback()
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.postTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.postTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleRemoveMemberFromTeam = (
  centerId: string,
  teamId: string,
  memberId: string,
  callback: () => void,
) => () => patch(`/r2d2/technicentres/${centerId}/agent/${memberId}/`, { equipe: null, active: false }).then(() => {
  store.dispatch(removeTeamMember({ teamId, agentId: memberId }))
  callback()
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.deleteTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.deleteTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})

export const handleChangeAgentTeam = (
  centerId: string,
  currentTeamId: string,
  targetTeamId: string,
  agent: { id: string; cp: string },
) => patch(`/r2d2/technicentres/${centerId}/agent/${agent.id}/`, { equipe: targetTeamId }).then(() => {
  store.dispatch(removeTeamMember({ teamId: currentTeamId, agentId: agent.id }))
  store.dispatch(addTeamMember({ teamId: targetTeamId, agent }))
  store.dispatch(setSnackbar({
    message: terms.Messages.success.manage.changeTeamMember,
    severity: SnackbarSeverity.SUCCESS,
  }))
}).catch(e => {
  const errorMessage = parseErrorMessage(e, terms.Messages.errors.manage.changeTeamMember)
  store.dispatch(setSnackbar({ message: errorMessage, severity: SnackbarSeverity.ERROR }))
})
