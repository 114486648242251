import { MouseEvent, ReactElement, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Menu, Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import packageJson from '../../../../package.json'
import { Item, MenuItemType } from '../Item'

import './MenuAccount.scss'

export default function AccountMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const auth = useAuth()
  const isMenuOpen = Boolean(anchorEl)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  if (!auth.isAuthenticated) return null
  return (
    <div>
      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowDownIcon />}>
        {`${auth?.user?.profile?.first_name} ${auth?.user?.profile?.family_name}`}
      </Button>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
        {Object.values(MenuItemType).map(item => (<Item key={item} type={item} closeMenu={handleMenuClose} />))}
        <span style={{ padding: '6px 16px', display: 'flex' }}>
          {`Version : ${packageJson.version}`}
        </span>
      </Menu>
    </div>
  )
}
