import { Check, DeleteOutlined, EditOutlined, Inventory2Outlined, ReplayOutlined } from '@mui/icons-material'
import { ModalConfirm, ModalPatchMissionTC, modalSignal } from 'components'
import { handleDeleteMissionTC, handlePatchMissionTC, handleValidateMissionTC } from 'services'
import { MISSION_ARCHIVE_STATUS, MISSION_DELETE_STATUS, MISSION_EDIT_STATUS, MissionStatus, MissionTC } from 'types'
import { post, useAppDispatch } from 'utils'
import { getMissionsTC } from 'reducers/missionTable/thunks'

import './MissionActions.scss'

interface Props {
  mission: MissionTC
  technicalCenterId: string
  disabled: boolean
  ordering: string
}

export default function MissionActions({ mission, technicalCenterId, disabled, ordering }: Props) {
  const dispatch = useAppDispatch()
  if (disabled) {
    return (<div className="mission-actions">-</div>)
  }
  const handleClickDelete = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir supprimer cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={() => handleDeleteMissionTC(technicalCenterId, mission.id, () => {
          modalSignal.value = null
          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
        })}
      />
    )
  }

  const handleClickEdit = () => {
    modalSignal.value = (
      <ModalPatchMissionTC
        handleClose={() => { modalSignal.value = null }}
        technicalCenterId={technicalCenterId}
        mission={mission}
        ordering={ordering}
      />
    )
  }

  const description = mission.missions_supplementaires.some(m => m?.gmao && !m.statut_identique_after_validation)
    ? 'Attention ! Des missions supplémentaires de la GMAO ne sont pas synchronisées !' : ''

  const handleClickArchive = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir archiver cette mission ?"
        description={description}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
          },
          { archivee: true },
        )}
      />
    )
  }

  const handleClickValidate = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir valider cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleValidateMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
          },
        )}
      />
    )
  }

  const handleClickRetryGMAO = async () => {
    const missionToRetry = mission.missions_supplementaires.filter(m => m?.gmao && !m.statut_identique_after_validation)
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir réessayer de synchroniser les missions supplémentaires de la GMAO ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={async () => Promise.allSettled(missionToRetry.map(m => post(
          // eslint-disable-next-line max-len
          `/r2d2/technicentres/${technicalCenterId}/missions/${mission.id}/missions-supplementaires/${m.id}/retry-gmao-stream/`,
          {},
        ))).then(() => {
          modalSignal.value = null
          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
        })}
      />
    )
  }

  return (
    <div className="mission-actions">
      {MISSION_EDIT_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickEdit}>
          <EditOutlined />
        </button>
      )}
      {MISSION_DELETE_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickDelete}>
          <DeleteOutlined />
        </button>
      )}
      {MISSION_ARCHIVE_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickArchive}>
          <Inventory2Outlined />
        </button>
      )}
      {mission.statut === MissionStatus.inProgress && mission.fin_npd && mission.orientation_motrice?.principale && (
        <button type="button" onClick={handleClickValidate}>
          <Check />
        </button>
      )}
      {mission.statut === MissionStatus.validated && mission.missions_supplementaires
        .some(m => m?.gmao && !m.statut_identique_after_validation) && (
        <button type="button" onClick={handleClickRetryGMAO}>
          <ReplayOutlined />
        </button>
      )}
    </div>
  )
}
