import { Close } from '@mui/icons-material'
import terms from 'assets/terms'
import {
  Button, ButtonStyle,
  modalSignal,
} from 'components'
import { handlePostNewMissionTC } from 'services'
import { cleanFormValues, useAppDispatch } from 'utils'
import { MissionStatus } from 'types'
import { getMissionsTC, getTCFilters } from 'reducers/missionTable/thunks'

import { useHandlers, useRequiredFields } from './formHandlers'
import './ModalAddMissionTC.scss'
import MissionTCFields from './fields/MissionTCFields'

interface Props {
  title: string;
  handleClose: () => void;
  technicalCenterId: string;
  ordering: string;
}

const localTerms = terms.Modals.AddMissionTC
export default function ModalAddMissionTC({
  title,
  handleClose,
  technicalCenterId,
  ordering,
}: Props) {
  const dispatch = useAppDispatch()
  const {
    formValues,
    handleInputChange,
    handleCheckboxChange,
    handleTimeChange,
    handleMultiSelectChange,
    handleChangeRame,
    handleUmDetailsChange,
    sortedMissionsOptions,
    sortedAdditionalMissionsOptions,
    handleSelectModele,
    pendingGmao,
  } = useHandlers(technicalCenterId)
  const [requiredFields] = useRequiredFields(formValues, sortedMissionsOptions)
  const isFormValid = requiredFields.every(field => formValues[field as keyof typeof formValues] !== '')

  const handleValidate = handlePostNewMissionTC(technicalCenterId, () => {
    modalSignal.value = null
    dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
    dispatch(getTCFilters(technicalCenterId))
  })

  // --- Change statut value based on submit button ---
  const handleSubmitWithAffectee = () => {
    const updatedFormValues = {
      ...formValues,
      statut: MissionStatus.assigned,
    }
    handleValidate(cleanFormValues(updatedFormValues))()
  }
  // ------

  return (
    <div className="modal-add-missiontc">
      <div className="header">
        <h2>{title}</h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <MissionTCFields
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSelectMission={handleSelectModele}
        handleCheckboxChange={handleCheckboxChange}
        handleTimeChange={handleTimeChange}
        handleMultiSelectChange={handleMultiSelectChange}
        handleChangeRame={handleChangeRame}
        handleUmDetailsChange={handleUmDetailsChange}
        technicalCenterId={technicalCenterId}
        requiredFields={requiredFields}
        isInProgress={false}
        missionOptions={sortedMissionsOptions}
        additionalMissionOptions={sortedAdditionalMissionsOptions}
        pendingGMAO={pendingGmao}
      />

      <div className="actions">
        <Button
          text={localTerms.assignment}
          onClick={handleSubmitWithAffectee}
          style={ButtonStyle.light}
          disabled={!isFormValid || formValues.agent === '' || (!formValues.modele && !formValues.gmao)}
        />
        <Button
          text={localTerms.preassignment}
          onClick={handleValidate(cleanFormValues(formValues))}
          disabled={(!formValues.modele && !formValues.gmao) || !formValues.rame}
        />
      </div>
    </div>
  )
}
