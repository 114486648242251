import { ArrowForwardIos } from '@mui/icons-material'
import { useState } from 'react'
import BackButton from './BackButton'
import SelectForm from './SelectForm'
import './DataPanel.scss'

type Option = {
  id: string
  libelle: string
}

interface Props {
  title: string
  details: string
  missionComment: string
  options: Option[]
  selectedOptions: Option[]
  handleValidate: (options: Option[], comment: string) => () => void
  disabled?: boolean
}

export default function DataPanel(
  { title, details, missionComment, options, selectedOptions, handleValidate, disabled }: Props,
) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="rd-mission-data-panel">
      <button type="button" onClick={() => setExpanded(true)} disabled={disabled}>
        <div className="text">
          <strong>{title}</strong>
          <span>{details}</span>
        </div>
        {!disabled && (
          <ArrowForwardIos />
        )}
      </button>
      {expanded && (
        <div className="expanded-data-panel">
          <BackButton onClick={() => setExpanded(false)} text="Retour à la mission" />
          <SelectForm
            title={title}
            options={options}
            missionComment={missionComment}
            selectedOptions={selectedOptions}
            handleValidate={(opt, com) => () => {
              handleValidate(opt, com)()
              setExpanded(false)
            }}
            handleClose={() => setExpanded(false)}
          />
        </div>
      )}
    </div>
  )
}
